import { Modulo11 } from "./iBoletoModulo";

class Boleto {
  private bankSlipNumber: string;

  constructor(bankSlipNumber: string) {
    this.bankSlipNumber = bankSlipNumber.replace(/[^\d]/g, "");
    if (!this.valid()) {
      throw new Error("Invalid bank slip number");
    }
  }

  public valid(): boolean {
    if (this.bankSlipNumber.length !== 47) return false;
    const barcode = this.barcode().split("");
    const checksum = barcode.splice(4, 1);

    return Modulo11(barcode.join("")).toString() === checksum.toString();
  }

  public barcode(): string {
    return this.bankSlipNumber.replace(
      /^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/,
      "$1$5$2$3$4"
    );
  }

  public number(): string {
    return this.bankSlipNumber;
  }

  public prettyNumber(): string {
    return this.bankSlipNumber.replace(
      /^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/,
      "$1.$2 $3.$4 $5.$6 $7 $8"
    );
  }

  public bank(): string {
    const bankCode = this.barcode().substring(0, 3);
    const banks: { [key: string]: string } = {
      "001": "Banco do Brasil",
      "007": "BNDES",
      "033": "Santander",
      "069": "Crefisa",
      "077": "Banco Inter",
      "102": "XP Investimentos",
      "104": "Caixa Econômica Federal",
      "140": "Easynvest",
      "197": "Stone",
      "208": "BTG Pactual",
      "212": "Banco Original",
      "237": "Bradesco",
      "260": "Nu Pagamentos",
      "341": "Itaú",
      "389": "Banco Mercantil do Brasil",
      "422": "Banco Safra",
      "505": "Credit Suisse",
      "633": "Banco Rendimento",
      "652": "Itaú Unibanco",
      "735": "Banco Neon",
      "739": "Banco Cetelem",
      "745": "Citibank"
    };
    return banks[bankCode] || "Unknown";
  }

  public currency(): { code: string; symbol: string; decimal: string } | null {
    const currencyCode = this.barcode()[3];
    return currencyCode === "9"
      ? { code: "BRL", symbol: "R$", decimal: "," }
      : null;
  }

  public expirationDate(): Date {
    const dueFactor = parseInt(this.barcode().substring(5, 9), 10);
    const daysInMs = 86400000;
    let baseDate: Date;
    let calculatedDate: Date;

    if (dueFactor >= 1000 && dueFactor <= 9946) {
      baseDate = new Date(17402364e5);
      calculatedDate = new Date(
        baseDate.getTime() + (dueFactor - 1000) * daysInMs
      );
    } else {
      baseDate = new Date(8762364e5);
      calculatedDate = new Date(baseDate.getTime() + dueFactor * daysInMs);
    }

    return calculatedDate;
  }

  public prettyAmount(): string {
    const currency = this.currency();

    if (!currency) return this.amount();
    return `${currency.symbol} ${this.amount().replace(".", currency.decimal)}`;
  }

  public amount(): string {
    const cleanedBarcode = this.barcode().replace(/[^0-9]/g, "");

    const valueField = cleanedBarcode.substring(10, 19);

    const valueInCents = parseInt(valueField, 10);

    return (valueInCents / 100).toFixed(2);
  }
}

export default Boleto;

