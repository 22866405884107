import { RouteConfig } from "vue-router";
import MarginBases from "../views/MarginBases.vue";
import MaintenanceMarginBases from "../views/MaintenanceMarginBases.vue";
import Charges from "../views/Charges.vue";
import DebtAgreements from "../views/DebtAgreements.vue";
import DebtAgreementsInstallments from "@/views/DebtAgreementsInstallments.vue";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Root",
    component: () =>
      import(
        /* webpackChunkName: "authenticator" */ "../views/Authenticator.vue"
      )
  },
  {
    path: "/auth",
    name: "Auth",
    component: () =>
      import(
        /* webpackChunkName: "authenticator" */ "../views/Authenticator.vue"
      )
  },
  // {
  //   path: "/cadastrar-empresa",
  //   name: "CompanyRegistration",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "authenticator" */ "../views/CompanyRegistration.vue"
  //     )
  // },
  {
    path: "/cadastrar-parceiro",
    name: "PartnerRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authenticator" */ "../views/PartnerRegistration.vue"
      )
  },
  {
    path: "/pagina-nao-autorizada",
    name: "PageNotAuthorized",
    component: () =>
      import(
        /* webpackChunkName: "pageNotAuthorized" */ "../views/error/PageNotAuthorized.vue"
      )
  },
  {
    path: "/take-a-selfie/:operationCode",
    name: "TakeSelfieUnico",
    component: () =>
      import(
        /* webpackChunkName: "TakeSelfieUnico" */ "../views/unico-camera.vue"
      ),
    meta: {
      showInMenu: false,
      icon: "mdi-tune",
      authRequired: false,
      companyIdRequired: false
    }
  },
  {
    path: "/painel",
    component: () =>
      import(
        /* webpackChunkName: "painel" */ "@/components/core/layouts/DashboardLayout.vue"
      ),
    meta: {
      breadCrumb: "Painel"
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
        meta: {
          title: "Home",
          breadCrumb: "Home",
          showInMenu: true,
          icon: "mdi-home",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_HOME"]
            },
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_HOME"]
            },
            {
              userType: "EMPLOYEE",
              permissions: ["MODULO_HOME"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_HOME"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_HOME"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      // {
      //   path: "administradores",
      //   name: "Admins",
      //   component: () =>
      //     import(/* webpackChunkName: "admins" */ "../views/Users.vue"),
      //   meta: {
      //     title: "Administradores",
      //     breadCrumb: "Administradores",
      //     showInMenu: true,
      //     icon: "mdi-account-group",
      //     requiredPermissions: ["LISTAR_USUARIOS"],
      //     authRequired: true,
      //     companyIdRequired: true,
      //   },
      // },
      {
        path: "empresas",
        name: "Companies",
        component: () =>
          import(/* webpackChunkName: "companies" */ "../views/Companies.vue"),
        meta: {
          title: "Empresas",
          breadCrumb: "Empresas",
          showInMenu: true,
          icon: "mdi-domain",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_EMPRESAS"]
            },
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_EMPRESAS"]
            },
            {
              userType: "EMPLOYEE",
              permissions: ["MODULO_EMPRESAS"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_EMPRESAS"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_EMPRESAS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      // {
      //   path: 'financeiras',
      //   name: 'Financials',
      //   component: () => import(/* webpackChunkName: "financials" */ '../views/Financials.vue'),
      //   meta: {
      //     title: "Financeiras",
      //     breadCrumb: 'Financeiras',
      //     showInMenu: true,
      //     icon: "mdi-bank",
      //     requiredPermissions: [],
      //     authRequired: true
      //   }
      // },
      {
        path: "usuarios",
        name: "Users",
        component: () =>
          import(/* webpackChunkName: "users" */ "../views/Users.vue"),
        meta: {
          title: "Usuários",
          breadCrumb: "Usuários",
          showInMenu: true,
          icon: "mdi-account-multiple",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_USUARIOS"]
            },
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_USUARIOS"]
            },
            {
              userType: "EMPLOYEE",
              permissions: ["MODULO_USUARIOS"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_USUARIOS"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_USUARIOS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "perfis-e-permissoes",
        name: "Permissions",
        component: () =>
          import(
            /* webpackChunkName: "groupsPermissions" */ "../views/ProfilesPermissions.vue"
          ),
        meta: {
          title: "Perfis e permissões",
          breadCrumb: "Perfis e permissões",
          showInMenu: true,
          icon: "mdi-security",
          requiredPermissions: [
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_PERFIS_E_PERMISSOES"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "emprestimos",
        name: "Loans",
        component: () =>
          import(/* webpackChunkName: "loans" */ "../views/Loans.vue"),
        meta: {
          title: "Empréstimos",
          breadCrumb: "Empréstimos",
          showInMenu: true,
          icon: "mdi-cash",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_EMPRESTIMOS"]
            },
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_EMPRESTIMOS"]
            },
            {
              userType: "EMPLOYEE",
              permissions: ["MODULO_EMPRESTIMOS"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_EMPRESTIMOS"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_EMPRESTIMOS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "propostas-ctps",
        name: "CtpsProposals",
        component: () =>
          import(
            /* webpackChunkName: "GlobalParameterManagement" */ "../views/CtpsProposals.vue"
          ),
        meta: {
          title: "Propostas CTPS Digital",
          breadCrumb: "Propostas CTPS Digital",
          showInMenu: true,
          icon: "mdi-account-hard-hat-outline",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_EMPRESTIMOS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      // {
      //   path: 'relatorios',
      //   name: 'Reports',
      //   component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
      //   meta: {
      //     title: "Relatórios",
      //     breadCrumb: 'Relatórios',
      //     showInMenu: true,
      //     icon: "mdi-file-chart",
      //     requiredPermissions: [],
      //     authRequired: true
      //     companyIdRequired: false,
      //   }
      // },

      // {
      //   path: "/painel/bases-de-margem-menu",
      //   component: () => import("../views/MarginBasesMenu.vue"),
      //   meta: {
      //     title: "Bases de Margem",
      //     showInMenu: true,
      //     icon: "mdi-file-percent",
      //     subgroup: true
      //   },
      //   children: [
      //     {
      //       path: "/",
      //       name: "MarginBases",
      //       component: MarginBases,
      //       meta: {
      //         title: "Bases de margem",
      //         breadCrumb: "Bases de margem",
      //         showInMenu: true,
      //         icon: "mdi-file-percent",
      //         requiredPermissions: [
      //           {
      //             userType: "ADMIN_GOOROO",
      //             permissions: ["MODULO_BASES_DE_MARGEM"]
      //           },
      //           {
      //             userType: "ADMIN_GROUP",
      //             permissions: ["MODULO_BASES_DE_MARGEM"]
      //           },
      //           {
      //             userType: "EMPLOYEE",
      //             permissions: ["MODULO_BASES_DE_MARGEM"]
      //           }
      //         ],
      //         authRequired: true,
      //         companyIdRequired: true
      //       }
      //     },
      //     {
      //       path: "bases-de-margem-consulta-manutencao",
      //       name: "MarginBasesConsultaManutencao",
      //       component: MaintenanceMarginBases,
      //       meta: {
      //         title: "Consulta e Manutenção",
      //         breadCrumb: "Consulta e Manutenção",
      //         showInMenu: true,
      //         icon: "mdi-file-percent",
      //         requiredPermissions: [
      //           {
      //             userType: "ADMIN_GOOROO",
      //             permissions: ["MODULO_BASES_DE_MARGEM"]
      //           },
      //           {
      //             userType: "ADMIN_GROUP",
      //             permissions: ["MODULO_BASES_DE_MARGEM"]
      //           },
      //           {
      //             userType: "EMPLOYEE",
      //             permissions: ["MODULO_BASES_DE_MARGEM"]
      //           }
      //         ],
      //         authRequired: true,
      //         companyIdRequired: false
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: 'averbacoes',
      //   name: 'Endorsements',
      //   component: () => import(/* webpackChunkName: "endorsements" */ '../views/Endorsements.vue'),
      //   meta: {
      //     title: "Averbações",
      //     breadCrumb: 'Averbações',
      //     showInMenu: true,
      //     icon: "mdi-cash-check",
      //     requiredPermissions: [],
      //     authRequired: true
      //     companyIdRequired: true,
      //   }
      // },
      {
        path: "consignacoes",
        name: "Consignments",
        component: () =>
          import(/* webpackChunkName: "consigments" */ "../views/Batches.vue"),
        meta: {
          title: "Consignações e Repasses",
          breadCrumb: "Consignações e Repasses",
          showInMenu: true,
          icon: "mdi-format-list-checks",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_CONSIGNACOES_E_REPASSES"]
            },
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_CONSIGNACOES_E_REPASSES"]
            },
            {
              userType: "EMPLOYEE",
              permissions: ["MODULO_CONSIGNACOES_E_REPASSES"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "/painel/cobrancas-menu",
        component: () => import("../views/ChargesMenu.vue"),
        meta: {
          title: "Cobranças",
          showInMenu: true,
          icon: "mdi-barcode",
          subgroup: true
        },
        children: [
          {
            path: "/",
            name: "Charges",
            component: Charges,
            meta: {
              title: "Cobranças",
              breadCrumb: "Cobranças",
              showInMenu: true,
              icon: "mdi-barcode",
              requiredPermissions: [
                {
                  userType: "ADMIN_GOOROO",
                  permissions: ["MODULO_COBRANCAS"]
                }
              ],
              authRequired: true,
              companyIdRequired: false
            }
          },
          {
            path: "acordos",
            name: "DebtAgreements",
            component: DebtAgreements,
            meta: {
              title: "Acordos",
              breadCrumb: "Acordos",
              showInMenu: true,
              icon: "mdi-hand-coin-outline",
              requiredPermissions: [
                {
                  userType: "ADMIN_GOOROO",
                  permissions: ["MODULO_COBRANCAS"]
                }
              ],
              authRequired: true,
              companyIdRequired: false
            }
          },
          {
            path: "parcelas",
            name: "InstallmentsDebtAgreements",
            component: DebtAgreementsInstallments,
            meta: {
              title: "Parcelas Acordos",
              breadCrumb: "Parcelas Acordos",
              showInMenu: true,
              icon: "mdi-hand-coin-outline",
              requiredPermissions: [
                {
                  userType: "ADMIN_GOOROO",
                  permissions: ["MODULO_COBRANCAS"]
                }
              ],
              authRequired: true,
              companyIdRequired: false
            }
          }
        ]
      },
      {
        path: "relatorios-gerenciais",
        name: "ManagementReports",
        component: () =>
          import(
            /* webpackChunkName: "billets" */ "../views/ManagementReports.vue"
          ),
        meta: {
          title: "Relatórios Gerenciais",
          breadCrumb: "Relatórios Gerenciais",
          showInMenu: true,
          icon: "mdi-chart-areaspline",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_RELATORIOS_GERENCIAIS"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_RELATORIOS_GERENCIAIS"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_RELATORIOS_GERENCIAIS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "meus-relatorios",
        name: "MyReports",
        component: () =>
          import(/* webpackChunkName: "billets" */ "../views/MyReports.vue"),
        meta: {
          title: "Meus Relatórios",
          breadCrumb: "Meus Relatórios",
          showInMenu: true,
          icon: "mdi-file-download",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_RELATORIOS_GERENCIAIS"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_RELATORIOS_GERENCIAIS"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_RELATORIOS_GERENCIAIS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "politicas-de-credito",
        name: "CreditPolicies",
        component: () =>
          import(
            /* webpackChunkName: "billets" */ "../views/CreditPolicies.vue"
          ),
        meta: {
          title: "Políticas de crédito",
          breadCrumb: "Políticas de crédito",
          showInMenu: true,
          icon: "mdi-credit-card-clock-outline",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_POLITICAS_DE_CREDITO"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "minhas-comissoes",
        name: "MyCommissions",
        component: () =>
          import(
            /* webpackChunkName: "partners" */ "../views/MyCommissions.vue"
          ),
        meta: {
          title: "Minhas Comissões",
          breadCrumb: "Minhas Comissões",
          showInMenu: true,
          icon: "mdi-handshake-outline",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_MINHAS_COMISSOES"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_MINHAS_COMISSOES"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_MINHAS_COMISSOES"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "parceiros",
        name: "Partners",
        component: () =>
          import(/* webpackChunkName: "partners" */ "../views/Partners.vue"),
        meta: {
          title: "Parceiros",
          breadCrumb: "Parceiros",
          showInMenu: true,
          icon: "mdi-handshake-outline",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_PARCEIROS"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_PARCEIROS"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_PARCEIROS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "relatórios",
        name: "Reports",
        component: () =>
          import(/* webpackChunkName: "reports" */ "../views/Reports.vue"),
        meta: {
          title: "Meus Relatórios",
          breadCrumb: "Meus Relatórios",
          showInMenu: true,
          icon: "mdi-handshake-outline",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_RELATORIOS"]
            },
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_RELATORIOS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "mutuarios",
        name: "Borrowers",
        component: () =>
          import(/* webpackChunkName: "borrowers" */ "../views/Borrowers.vue"),
        meta: {
          title: "Tomadores",
          breadCrumb: "Tomadores",
          showInMenu: true,
          icon: "mdi-account-cash-outline",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_TOMADORES"]
            },
            {
              userType: "ADMIN_GROUP",
              permissions: ["MODULO_TOMADORES"]
            },
            {
              userType: "EMPLOYEE",
              permissions: ["MODULO_TOMADORES"]
            },
            {
              userType: "PARTNER_MASTER",
              permissions: ["MODULO_TOMADORES"]
            },
            {
              userType: "PARTNER",
              permissions: ["MODULO_TOMADORES"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "perfil",
        name: "Profile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/common/Profile.vue"
          ),
        meta: {
          breadCrumb: "Perfil",
          showInMenu: false,
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: []
            },
            {
              userType: "ADMIN_GROUP",
              permissions: []
            },
            {
              userType: "EMPLOYEE",
              permissions: []
            },
            {
              userType: "PARTNER_MASTER",
              permissions: []
            },
            {
              userType: "PARTNER",
              permissions: []
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      },
      {
        path: "gerenciar-parametros",
        name: "GlobalParameterManagement",
        component: () =>
          import(
            /* webpackChunkName: "GlobalParameterManagement" */ "../views/GlobalParameterManagement.vue"
          ),
        meta: {
          title: "Gerenciar Parâmetros",
          breadCrumb: "Gerenciar Parâmetros",
          showInMenu: true,
          icon: "mdi-tune",
          requiredPermissions: [
            {
              userType: "ADMIN_GOOROO",
              permissions: ["MODULO_GERENCIA_PARAMETROS_GLOBAIS"]
            }
          ],
          authRequired: true,
          companyIdRequired: false
        }
      }
    ]
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/error/PageNotFound.vue")
  }
];

export default routes;
